import { takeLatest, spawn, put } from 'redux-saga/effects';
import { appActions } from '../../../commons/reducer';
import { update } from '../../../utils/api';
import { updateAddressActions, UPDATE_ADDRESS } from './reducer';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { cleaningNullValues } from '../../../utils/functions';

const MySwal = withReactContent(Swal)

function* updateAddress() {
  yield takeLatest(UPDATE_ADDRESS.UPDATE_ADDRESS, function* fn(action: any): any {
    const { form } = action
    const data = cleaningNullValues({
      nameOfIndividual: form.nameOfIndividual,
      email: form.email,
      phone: form.phone,
      address: cleaningNullValues(form.address),
      isFavorite: form.isFavorite
    })
    
    try {
      const response = yield update(`/addresses-book/${form.id}`, data);
      if (response.error) {
        yield put(updateAddressActions.updateAddressFailed({ response: response.errorMsg }));
        MySwal.fire({
          icon: 'error',
          title: `Ooops, tenemos problemas`,
          text: response.errorMsg,
        })
      } else {
        yield put(updateAddressActions.updateAddressSuccess(response));
      }
    } catch {
      yield put(appActions.serverFailed('Oops, server failed'));
    }
  }
  );
}
export default function* saga(): any {
  yield spawn(updateAddress);
}